<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>
        <ion-title>{{ folder }}</ion-title>
      </ion-toolbar>
    </ion-header>
    
    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">{{ folder }}</ion-title>
        </ion-toolbar>
      </ion-header>
		<div id="container">
			<form @submit.prevent>
				<ion-item>
					<ion-input placeholder="Введите название темы" v-model="forms.title"></ion-input>
				</ion-item>
				<ion-item>
					<ion-textarea placeholder="Текст темы" v-model="forms.text"></ion-textarea>
				</ion-item>
				<div>
					<ion-button expand="block" size="large" color="primary" @click="sendTopic()">Создать тему</ion-button>
				</div>
			</form>	
		</div>
    </ion-content>
  </ion-page>
</template>

<style scoped>
ion-menu-button {
  color: var(--ion-color-primary);
}
ion-item{
    --inner-padding-end: 0.5%;
    --padding-start:0.5%;
	padding-bottom: 20px;
}
.post {
	padding-bottom: 20px;
}
.post__title {
    font-weight: bold;
	font-size: 120%;
}
.post__date {
	color: #808080;
    font-size: 80%;
    margin-left: 1rem;
}
.post__body {
    display: flex;
}
.post__text {
    flex-grow: 1;
	word-break: break-word;
}
.post__author {
    text-align: center;
	margin-right: 1%;
	max-width: 120px;
}
.post__sign {
    font-size: 80%;
    color: #808080;
}
</style>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonItem, IonButton, IonTextarea, IonInput } from '@ionic/vue';
import { useRoute,useRouter } from 'vue-router';
import axios from 'axios';
import useDataService from "../dataservice";

export default {
	name: 'TopicNew',
	components: {
		IonButtons,
		IonContent,
		IonHeader,
		IonMenuButton,
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonButton,
		IonTextarea,
		IonInput
	},
	methods: {
	},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const folder = 'Создать новую тему форума';
	const { token } = useDataService();

	const forms = {title:'',text:''};
	
	const sendTopic = function(){
		if(forms.title.length > 3 && forms.title.length > 3){
			axios.post('https://beon.fun/api/v1/topic',{
				'title': forms.title,
				'text': forms.text,
				'forum': route.params.name
			},{
			headers: {
				Authorization: 'Bearer ' + token.value,
			}})
			.then((response) => {
				if(response.data.status == 'ok'){
					router.replace('/topic/' + response.data.topicid )
				}
			});
		}
	};

    return { folder, forms, sendTopic }
  }
}
</script>